// Define Global Layout for stubhub.com
import React from 'react';

import {useSmartBannerUpdater} from '@stubhub/hook-utils';
import {publish} from '@stubhub/pubsub-utils';
import BranchIO from '@stubhub/react-branch-io';
import CookieModal from '@stubhub/react-cookie-modal';
import DeviceToggle from '@stubhub/react-device-toggle';
import FeatureToggle from '@stubhub/react-feature-toggle';
import Simility from '@stubhub/react-simility';
import {UnifiedLayoutWithRouter} from '@stubhub/react-unified-app';

import './messages';
import {EXIT_INTENT_EVENTS} from './tracking/track-enum';
import TrackListener from './tracking/track-listener';
import FEATURE_FLAGS from './utils/featureFlags';
import {useExitIntent} from './utils/hooks/useExitIntent';

import './index.scss';

export default function Layout(props) {
  const {registerHandler} = useExitIntent();
  useSmartBannerUpdater();

  registerHandler({
    id: 'openModal',
    handler: () => publish(EXIT_INTENT_EVENTS.LOADED),
  });

  return (
    <>
      <FeatureToggle name={FEATURE_FLAGS.common.cookiesModal} key="cookiesModal">
        <CookieModal />
      </FeatureToggle>
      <TrackListener />
      <UnifiedLayoutWithRouter {...props} />
      <FeatureToggle name={FEATURE_FLAGS.common.enableBranchIO} key="branchIO">
        <DeviceToggle on={['phone']}>
          <BranchIO />
        </DeviceToggle>
      </FeatureToggle>
      <FeatureToggle name={FEATURE_FLAGS.common.tns.simility} key="Simility">
        <Simility />
      </FeatureToggle>
    </>
  );
}
