export const HOME_EVENTS = {
  MAIN: 'home',
  LOADED: 'home.loaded',
  CATEGORY: 'home.category',
  NEWSLETTER: 'home.newsletter',
};

export const ENTITY_EVENTS = {
  MAIN: 'entity',
  LOADED: 'entity.loaded',
};

export const EVENT_PAGE = {
  MAIN: 'event',
  LOADED: 'event.loaded',
  EVENT_DETAILS: 'event.event_details',
  LISTINGS: 'event.byo',
};

export const TICKET_EVENTS = {
  MAIN: 'ticket',
  FULLFILMENT_ERROR: 'ticket.fullfilment.not_found',
};

export const SEARCH_EVENTS = {
  MAIN: 'search',
  LOADED: 'search.loaded',
  TERM: 'search.term',
};

export const NOT_FOUND_EVENTS = {
  MAIN: 'notFound',
  LOADED: 'notFound.loaded',
};

export const EXIT_INTENT_EVENTS = {
  MAIN: 'exitIntent',
  LOADED: 'exitIntent.loaded',
};
