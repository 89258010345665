import {createSelector} from 'reselect';

import {getCatGrpAttributes, isGeography} from '@stubhub/entity-helper';
import {selectors as dateFilterSelectors} from '@stubhub/react-date-filter';
import {selectors as entityFilterSelectors} from '@stubhub/react-entity-grouping-filter';
import {getPartnerEntityTheme} from '@stubhub/react-partner-helper';
import {Controller} from '@stubhub/react-store-provider';

import {storeToSearchRadiusMapping} from '../../modules/react-event-list-panel/event-list-panel-helper';
import {isCBTModuleEnabledSelector} from '../../store/gsConfig/FF/selectors';
import {storeIdSelector} from '../../store/gsConfig/selectors';
import {searchEvents} from '../../utils/api';
import {getEntity} from '../../utils/catalog';
import DateTimeFormat from '../../utils/datetimeformat';
// eslint-disable-next-line import/no-named-as-default
import isBannedEntity from '../../utils/uk-cbt-banned-sites';

import {
  entityLoadedSelector,
  geoIncludeRadiusEventsSelector,
  eventsLoadingSelector,
  entitySelector,
  noEventsSelector,
  groupedEventsTotalSelector,
} from './helpers/entity-selectors';
import {showTrustMessagesSelector} from './helpers/ff-selectors';

const geoSelector = createSelector(
  (state) => state.user,
  (userState = {}) => userState.location || {}
);
const currentLocationSelector = createSelector(
  (state) => state.user,
  (userState = {}) => userState.currentLocation || {}
);

const deviceSelector = createSelector(
  (state) => state.device,
  (device) => device.type
);
const botSelected = createSelector(
  (state) => state.device,
  (device) => device.isBot
);

const viewportSelector = (state) => state.viewport;
const screenSizeSelector = createSelector(viewportSelector, (viewport = {}) => viewport.screenSize);
// Const entityIdSelector = createSelector((localState) => localState.entityId, (entityId) => entityId);
const entityTypeSelector = createSelector(
  (localState) => localState.entity,
  (entity = {}) => entity.entityType
);

export const NAMESPACE = 'app.entity';
const CATALOG_API_TIMEOUT_CLIENT = 10000;
const CATALOG_API_TIMEOUT_SERVER = 5000;

const CATALOG_API_TIMEOUT = __CLIENT__ ? CATALOG_API_TIMEOUT_CLIENT : CATALOG_API_TIMEOUT_SERVER;

/**
 * Method to Fetch the Performer from the Catalog Performer API.
 * @param entityType [string] The Type of the entity to be used to fetch the information.
 * @param entityId [string] The Id of the performer to be used to fetch the information.
 * @param updateTheme [function] Function provided by the 'ThemeProvider' to update the theme.
 *
 */
function loadEntity(entityType, entityId, updateTheme) {
  return (dispatch, getState) => {
    const state = getState();
    const {lang} = state;
    const shstoreId = storeIdSelector(state);
    let entity;
    const pid = `${entityType}/${entityId}`;
    dispatch({type: 'ENTITY_LOADING', loading: true, error: false, entity, pid});

    return getEntity(entityType, entityId, shstoreId, lang, false, {}, CATALOG_API_TIMEOUT)
      .then((entity) => {
        entity = {...entity, ...getCatGrpAttributes(entity), entityType};

        /**
         * Update Theme Function should be inherited from the Theme Provider.
         *
         */
        if (typeof updateTheme === 'function') {
          const theme = getPartnerEntityTheme(entity) || {};
          updateTheme(theme);
        }

        return dispatch({type: 'ENTITY_LOADED', loading: false, error: false, entity});
      })
      .catch((err) => {
        return dispatch({type: 'ENTITY_ERROR', loading: false, error: err});
      });
  };
}

/**
 * Method to Reset the Performer Page redux state.
 *
 */
function resetEntityPage() {
  return (dispatch) => {
    dispatch({type: 'UPDATE_THEME', theme: {}});
    dispatch({type: 'ENTITY_RESET'});
  };
}

function loadEvents(props, reset) {
  /* Ignore default value assignments */
  /* istanbul ignore next */
  let {events = [], offset = 0} = props;
  const {geo, dateFilter, includeRadius, sort, reRankBy, shstoreId, spellCheck, id, disableGeoLocation} = props;

  let searchParams = {...props.searchParams};
  const actionType = 'EventListPanel.loadEvents';

  /**
   * Geo Location Section
   *
   */
  if (!disableGeoLocation && geo && geo.key) {
    searchParams.point = geo.latitude && geo.longitude ? `${geo.latitude},${geo.longitude}` : null;
    searchParams.excludeFromRadius = !includeRadius;
  }

  /**
   * Use the Radius id passed externally
   *
   */
  searchParams.radius = searchParams.radius || storeToSearchRadiusMapping(shstoreId);

  if (reset) {
    events = [];
    offset = 0;
  }

  searchParams.dateLocal =
    dateFilter && dateFilter.start && dateFilter.end
      ? `${DateTimeFormat.toAPIString(dateFilter.start)} TO ${DateTimeFormat.toAPIString(dateFilter.end)}`
      : null;
  searchParams.sort = sort;
  searchParams.reRankBy = reRankBy;
  searchParams.start = offset;
  searchParams.shstore = shstoreId;
  searchParams.spellCheck = spellCheck;

  return (dispatch, getState) => {
    const state = getState();

    /**
     * Add Entity Filter SearchSpecs to the current Search Params
     *
     */
    const entityFilter = entityFilterSelectors.getEntityFilterSearchSpecFromState(state);
    if (entityFilter) {
      searchParams = {...searchParams, ...entityFilter};
    }

    dispatch({type: actionType, loading: true, events});

    return searchEvents(searchParams, state.gsConfig).then((body) => {
      events = events.concat(body.events);
      offset += body.events.length;
      const {numFound, didYouMean} = body;
      const hasMore = offset < numFound;
      dispatch({type: actionType, loading: false, events, numFound, offset, hasMore, didYouMean, localStateId: id});

      return null;
    });
  };
}

const controller = new Controller({
  namespace: NAMESPACE,

  reducers: ['ENTITY_LOADING', 'ENTITY_LOADED', 'ENTITY_ERROR', 'ENTITY_EVENT_METAINFO', 'SORT_SELECTOR_CHANGED'],

  actionCreators: {
    loadEntity,
    resetEntityPage,
    loadEvents,
  },

  mapStateToProps(state) {
    const localState = this.getLocalState(state);
    const referrer = state.referrer || '';

    /**
     * Event List Redux State
     *
     */
    const loaded = entityLoadedSelector(state);
    const entityType = entityTypeSelector(localState);
    const entity = entitySelector(localState);

    const localEvents = geoIncludeRadiusEventsSelector(state);
    const localEventIndex = (loaded && localEvents.offset) || 0;

    /**
     * Geography Entity Pages Should Display GEOS SEO Links first and if there are no Geos under the current Geography Entity then show the VENUE SEO
     *
     */
    let geographiesSEOLinkCount;
    if (isGeography(entityType)) {
      const {numFound, loading} = state[`${NAMESPACE}.${entityType}.seo.links`] || {};
      if (!loading) {
        geographiesSEOLinkCount = numFound;
      }
    }
    const {id, parentGroupingId} = entity || {};
    const isCBTModuleException = isBannedEntity({entityType, id, parentGroupingId});

    return {
      content: localState.content,
      geo: geoSelector(state),
      currentLocation: currentLocationSelector(state),
      dateFilter: dateFilterSelectors.dateFilter(state),
      sort: localState.sort,
      referrer,
      loaded,
      localEventIndex,
      deviceType: deviceSelector(state),
      isBot: botSelected(state),
      entity,
      pid: localState.pid,
      noEvent: noEventsSelector(state),
      screenSize: screenSizeSelector(state),
      geographiesSEOLinkCount,
      groupedEventsTotal: groupedEventsTotalSelector(state),
      eventsLoading: eventsLoadingSelector(state),
      isCBTModuleEnabled: isCBTModuleEnabledSelector(state),
      isCBTModuleException,
      showTrustMessages: showTrustMessagesSelector(state),
      error: localState.error,
    };
  },
});

controller.addResetReducer(['ENTITY_RESET']);

export default controller;
