import {get as restMethodGet} from '@stubhub/rest-method';

import {CMA_DEFAULTS, SEARCH_CACHE_MAX_AGE} from './constants';
import FEATURE_FLAGS from './featureFlags';

const _headers = {
  'Accept-Language': 'en-us',
};

function getRequestHeaderSettings(headers, omitAuth) {
  const auth = {
    Authorization: process.env.REACT_APP_API_SECRET,
  };

  return {..._headers, ...(omitAuth ? null : auth), ...headers};
}

export const get = (options = {}) => {
  const {path, json, query, body, timeout, auth, cache = false, cacheMaxAge, proxy} = options;
  let {host, headers} = options;
  if (/^http/i.test(path)) {
    host = undefined;
  } else {
    host = host || process.env.REACT_APP_API_HOST;
  }

  headers = getRequestHeaderSettings(headers, auth === false);

  return restMethodGet({host, path, json, query, body, timeout, headers, cache, cacheMaxAge, proxy});
};

export const getJSON = (options = {}) => {
  options.json = true;

  return get(options);
};

const DEFAULT_RADIUS = 200;

/**
 * Method to Fetch the Event from Search Catalog Events API. Please refer to the link below for the detailed documentation for this API.
 * @link https://wiki.stubcorp.com/pages/viewpage.action?pageId=20354722
 *
 */
export const searchEvents = (
  {
    associatedEvents,
    shstore = 1,
    id,
    performerId,
    geoId,
    categoryId,
    groupingId,
    venueId,
    dateLocal,
    date,
    units,
    parking,
    sourceId = '0 |1 |4001 |5001 |29001',
    status = 'active |contingent',
    start = 0,
    rows = 20,
    spellCheck = true,
    geoExpansion = true,
    boostByCategory = true,
    lang = true,
    includeNonEventEntities = true,
    edgeControlEnabled = true,
    sort = 'eventDateLocal asc',
    reRankBy = 'relevance',
    improvedRelevancy = true,
    term,
    q,
    point,
    radius,
    excludeFromRadius = false,
    radiusFrom,
    radiusTo,
    fieldList = 'id,ticketInfo,distance,name,eventDateLocal,categories,groupings,performers,venue,eventInfoUrl,displayAttributes,description,imageUrl,createdDate,performersCollection,eventType,metaInfo,status',
    gameType,
    eventType,
    aggFields,
    aggLimit,
    eventMonth,
    dayAndTime,
    performerRole,
    minAvailableTickets,
    needSEOMeta = false,
  },
  gsConfig = {}
) => {
  const hideCbtEvents = gsConfig.features && gsConfig.features[FEATURE_FLAGS.common.hideCbtEvents];

  /**
   * Associated Events
   *
   */
  if (associatedEvents) {
    fieldList += ',associatedEvents';
  }

  /**
   * Need seo meta info
   *
   */
  if (needSEOMeta) {
    fieldList += ',seoMeta';
  }

  /**
   * Enforcing Default Search Radius when location is set.
   *
   */
  if (point) {
    radius = radius || DEFAULT_RADIUS;
    geoExpansion = false;
  }

  /**
   * Enforcing UKCMA compliance by filtering non-UK events
   *
   */
  if (hideCbtEvents) {
    geoId = CMA_DEFAULTS.GEO_ID;
    geoExpansion = false;
  }

  const params = {
    shstore,
    status,
    spellCheck,
    boostByCategory,
    lang,
    includeNonEventEntities,
    edgeControlEnabled,
    fieldList,
    sourceId,
    parking,

    /**
     * Geo-location
     *
     */
    point,
    radius,
    excludeFromRadius,

    radiusFrom,
    radiusTo,

    units,
    geoExpansion,

    /**
     * Pagination
     *
     */
    start,
    rows,

    /**
     * Date Filters
     *
     */
    dateLocal,
    date,
    minAvailableTickets,

    /**
     * Sort params
     *
     */
    sort,
    reRankBy,
    improvedRelevancy,

    /**
     * Filter And Facets Params
     *
     */
    gameType,
    eventType,
    aggFields,
    aggLimit,
    eventMonth,
    dayAndTime,
    performerRole,

    /**
     * Main Search Criteria
     * Note - Only One of these should be passed at time, Passing two or more might result in Zero results.
     *
     */
    id,
    performerId,
    geoId,
    categoryId,
    groupingId,
    venueId,
    q: q || term,
  };

  return getJSON({
    host: process.env.REACT_APP_API_HOST,
    path: '/search/catalog/events/v3/',
    query: params,
    headers: {'Accept-Language': gsConfig.currentLocale},
    cache: true,
    cacheMaxAge: SEARCH_CACHE_MAX_AGE,
  });
};
