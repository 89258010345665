import {SECONDS_OF_MIN} from './timeConsts';

const MINS_15 = 15;
const DEFAULT_CACHE_MAX_AGE = 300;

export const SEARCH_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_SEARCH, 10) || 5 * SECONDS_OF_MIN; // 5mins
export const CATALOG_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_CATALOG, 10) || MINS_15 * SECONDS_OF_MIN; // 15mins
export const CMS_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_CATALOG, 10) || MINS_15 * SECONDS_OF_MIN; // 15mins
export const BILLBOARD_CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_BILLBOARD, 10) || SECONDS_OF_MIN; // 1min
export const CACHE_MAX_AGE = parseInt(process.env.REACT_APP_CACHE_TTL_SEARCH, 10) || DEFAULT_CACHE_MAX_AGE;

export const CATEGORY_IDS = {
  CONCERTS: 1,
  SPORTS: 28,
  MOTOR: 110,
  GOLF: 111,
  WRESTLING: 113,
  FOOTBALL: 114,
  THEATER: 174,
  BALLET_AND_DANCE: 176,
  OPERA_CLASSICAL_MUSIC: 178,
  SPEAKING_TOUR_CONVENTIONS: 194,
  COMEDY: 209,
  FAMILY: 5242,
  RODEO: 6979,
  FIGHT: 7368,
  TENNIS: 7667,
  HORSE_RACING: 7851,
  ALTERNATIVE: 178737,
  COUNTRY_FOLK: 195487,
  DANCE_ELECTRONIC: 195489,
  HARD_ROCK_METAL: 195736,
  RAP_HIP_HOP: 195739,
  RB_SOUL: 195742,
  POP: 195743,
  ROCK: 209736,
  FESTIVALS: 490277,
  MUSICAL: 700188,
  STAGE_SHOWS_PLAYS: 700189,
  HARDCORE: 739171,
  INDIE: 739173,
  PUNK: 739181,
};

export const PERFORMER_IDS = {
  COMMUNITY_SHIELD: 735422,
  EURO_2020: 1520757,
  EVERTON: 165237,
  GIFT_CARD: 7033,
  MANCHESTER_CITY: 165242,
  MANCHESTER_UNITED: 7367,
  METALLICA: 8147,
};

export const GROUPING_IDS = {
  CHAMPIONS_LEAGUE: 727754,
  COLLEGUE_BASKETBALL: 333,
  COLLEGUE_FOOTBALL: 122,
  SUPERBOWL: 542,
  EURO_2024: 1507012,
  EUROPA_LEAGUE: 727752,
  EUROPA_LEAGUE_FINAL: 50031079,
  FA_CUP: 720071,
  MLB: 81,
  NBA: 115,
  NFL: 121,
  NHL: 144,
  PREMIER_LEAGUE: 154987,
  SOCCER_TOURNAMENT: 165489,
  WORLD_SERIES: 121418,
  ENGLAND_NATIONAL_FOOTBALL_LEAGUE: 711304,
};

// https://wiki.stubcorp.cloud/pages/viewpage.action?pageId=44707642
export const DELIVERY_METHOD_IDS = {
  ELECTRONIC_DOWNLOAD: 1,
  ELECTRONIC_INSTANT_DOWNLOAD: 2,
  FEDEX_PRIORITY_OVERNIGHT_AK_HI: 3,
  FEDEX_STANDARD_OVERNIGHT: 4,
  FEDEX_PRIOTITY_OVERNIGHT_CONTINENTAL_US: 5,
  FEDEX_INTRA_CANADA_OVERNIGHT: 6,
  FEDEX_TWO_DAY: 7,
  FEDEX_SATURDAY: 8,
  FEDEX_INTERNATIONAL_ECONOMY: 9,
  WILLCALL: 10,
  KIOSK: 11,
  PICKUP: 12,
  EMAIL: 13,
  COURIER: 14,
  FEDEX_INTERNATIONAL_PRIORITY_PUERTO_RICO: 15,
  FEDEX_INTERNATIONAL_PRIORITY_CANADA: 16,
  PICKUP_EVENT_DAY: 17,
  OFFSITE_PICKUP: 18,
  HOSPITALITY: 19,
  UPS_WORLDWIDE_SAVER: 22,
  UPS_NEXT_BUSINESS_DAY_SAVER: 23,
  UPS_TWO_BUSINESS_DAYS: 24,
  UPS_NEXT_BUSINESS_DAY_AIR: 25,
  UPS_EXPRESS_SAVER_INTRA_CA: 27,
  UPS_STANDARD_INTRA: 36,
  ROYAL_MAIL: 37,
  DEUTSCHE_POST_INTRA_DE: 38,
  DEUTSCHE_POST_WORLDWIDE: 39,
  FLASHSEAT_INSTANT: 40,
  MOBILE_TICKET_INSTANT: 41,
  MOBILE_TICKET: 42,
  MOBILE_TRANSFER: 43,
  FLASH_TRANSFER: 44,
  FLASHSEAT: 45,
  SEASON_CARD_COURIER: 46,
  ELECTRONIC_AND_MOBILE_TICKET: 47,
  ELECTRONIC_AND_MOBILE_TICKET_INSTANT: 48,
  LOCAL_DELIVERY: 49,
  COURIER_WORLDWIDE: 50,
};

export const DELIVERY_TYPE_IDS = {
  ELECTRONIC: 1,
  ELECTRONIC_INSTANT_DOWNLOAD: 2,
  FEDEX: 3,
  PICKUP: 4,
  UPS: 5,
  ROYAL_MAIL: 6,
  DEUTSCHE_POST: 7,
  MOBILEID: 8,
  MOBILE_TICKET: 9,
  MOBILE_TRANSFER: 10,
  COURIER: 11,
  MOBILE_TICKET_INSTANT: 12,
  MOBILEID_NON_INSTANT: 13,
  ELECTRONIC_AND_MOBILE_TICKET: 14,
  ELECTRONIC_AND_MOBILE_TICKET_INSTANT: 15,
  LOCAL_DELIVERY: 16,
};

export const paperTicketMatchIds = [
  DELIVERY_TYPE_IDS.FEDEX,
  DELIVERY_TYPE_IDS.PICKUP,
  DELIVERY_TYPE_IDS.UPS,
  DELIVERY_TYPE_IDS.ROYAL_MAIL,
  DELIVERY_TYPE_IDS.DEUTSCHE_POST,
  DELIVERY_TYPE_IDS.COURIER,
  DELIVERY_TYPE_IDS.LOCAL_DELIVERY,
];
export const electronicTicketMatchIds = [DELIVERY_TYPE_IDS.ELECTRONIC, DELIVERY_TYPE_IDS.ELECTRONIC_INSTANT_DOWNLOAD];
export const mobileTicketMatchIds = [
  DELIVERY_TYPE_IDS.MOBILEID,
  DELIVERY_TYPE_IDS.MOBILE_TICKET,
  DELIVERY_TYPE_IDS.MOBILE_TICKET_INSTANT,
  DELIVERY_TYPE_IDS.ELECTRONIC_AND_MOBILE_TICKET,
  DELIVERY_TYPE_IDS.ELECTRONIC_AND_MOBILE_TICKET_INSTANT,
];
export const mobileTransferMatchIds = [DELIVERY_TYPE_IDS.MOBILE_TRANSFER];
export const instantDeliveryIds = [
  DELIVERY_TYPE_IDS.ELECTRONIC_INSTANT_DOWNLOAD,
  DELIVERY_TYPE_IDS.MOBILEID,
  DELIVERY_TYPE_IDS.MOBILE_TICKET_INSTANT,
  DELIVERY_TYPE_IDS.ELECTRONIC_AND_MOBILE_TICKET_INSTANT,
];

// https://wiki.stubcorp.cloud/pages/viewpage.action?pageId=45336440
export const EXTERNAL_SOURCE_IDS = {
  STUBHUB_SEO: 30001,
};

export const CMA_DEFAULTS = {
  GEO_ID: 6628,
};

export const TICKET_MEDIUMS = Object.freeze({
  PAPER: 1,
  PDF: 2,
  BARCODE: 3,
  FLASH_SEAT: 4,
  SEASON_CARD: 5,
  EVENT_CARD: 6,
  EXT_MOBILE: 7,
  EXT_FLASH: 8,
  MOBILE: 9,
  WRISTBAND: 10,
  RFID: 11,
  GUEST_LIST: 12,
});

export const DEVICE_TYPE = {
  PHONE: 'phone',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

export const SCREEN_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const DISPLAY_TYPE_EVENT_LIST = 'List';
export const DISPLAY_TYPE_DATE_CARDS = 'DateCards';
export const DISPLAY_TYPE_CALENDAR = 'Calendar';
export const DISPLAY_TYPE_EVENTS_ONLY = 'EVENT_TICKETS';
export const DISPLAY_TYPE_VIP = 'VIP_TICKETS';
export const DISPLAY_TYPE_OTHER = 'OTHER_TICKETS';
