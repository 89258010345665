const FEATURE_FLAGS = {
  common: {
    advisory: {
      /** Is advisory currency enabled? */
      enabled: 'common.enableAdvisoryCurrency',

      /** Enables opt out */
      optOutEnabled: 'common.advisory.enableOptOut',
    },
    tns: {
      /** Is tns simility enabled? */
      simility: 'common.tns.isSimilityOn',
    },

    /** Is cookies modal enabled? */
    cookiesModal: 'common.cookiesModal.show',

    /** Feature toggle for GTM */
    enableBranchIO: 'common.enableBranchIO',
    trustMessages: 'common.enableTrustMessaging',
    fanProtectRestrictions: 'common.fanProtect.showRestrictions',
    queryParams: 'common.queryParams',
    eplChanges: 'homepage.showUKcmaFeatures.enableEPLChanges',
    cma: {
      show: 'homepage.showUKcmaFeatures',
      retainRedirect: 'homepage.showUKcmaFeatures.retainRedirectPathname',
    },
    interceptModal: 'common.enableInterceptModalForCA',
    paypal: {
      /** Show PayPal Pay Later messages */
      payLater: 'common.paypal.payLater',
    },

    /** Show new CBT module */
    cbtModule: {
      show: 'common.cbtModule.show',
    },
    redirectModal: {
      show: 'common.redirectModal.showRedirectModal',
    },

    /** Hide CBT events? */
    hideCbtEvents: 'common.cbtEvents.hide',
  },
  byo: {
    legal: {
      nonOfficial: {
        show: 'byo.legal.nonOfficial.show',
      },
      fanProtectWarranty: {
        show: 'byo.legal.fanProtectWarranty.show',
      },
      priceSetBySeller: {
        show: 'byo.legal.priceSetBySeller.show',
      },
      priceVaryFaceValue: {
        show: 'byo.legal.priceVaryFaceValue.show',
      },
    },
    map: {
      show: 'byo.map.show',
    },
  },
  event: {
    showValuePercentage: 'event.showValuePercentage',
    importantInfoModal: 'event.importantInfoModal.show',
    legal: {
      priceIncludeVAT: 'event.legal.priceIncludeVAT.show',
      hideEstimatedFee: 'event.legal.estimatedFee.hide',
      priceIncludeFees: 'event.legal.priceIncludeFees.select',
      showLegalMessages: 'event.legalMessages.show',
    },
    showUKcmaFeatures: 'event.showUKcmaFeatures',
  },
  browse: {
    viewTickets: 'browse.viewTickets.enable',
    mWebPerformerPrice: 'browse.mWebPerformerPrice.enable',

    /** Should banner skeleton be displayed? */
    showBannerSkeleton: 'browse.banner.displaySkeleton',
  },
  breadcrumb: {
    hideHome: 'breadCrumb.hideHome',
    cleanName: 'breadCrumb.useCleanName',
  },
  city: {
    enabled: 'geography.cityPage.enable',
    geoLevelDisabled: 'geography.cityPage.geoLevelCheck.disable',
    dateFilter: 'geography.citypage.enableDateFilter',
  },
  category: {
    hideTabs: 'common.hideTabsConfigOnCategoryGroupingEntity',
  },
  performer: {
    relatedArtists: 'browse.relatedArtists.enable',
    seoVenues: 'browse.venue.performerVenues.enable',
  },
  grouping: {
    seoVenues: 'browse.venue.groupingVenues.enable',
  },
  venue: {
    nearby: 'browse.venue.nearbyVenues.enable',
    performers: 'browse.venue.venuePerformers.enable',
  },
  payment: {
    /** Is Apple pay enabled ? */
    applePay: 'checkout.payment.enableApplePay',
  },
};

export default FEATURE_FLAGS;
